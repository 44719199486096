<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="left">
          <h3>陕西西咸新区服务贸易协会行业自律公约</h3>
          <h5>Industry Self-discipline</h5>
          <p>为了扎实开展行业协会廉洁诚信建设工作，明确责任、率先垂范，充分发挥行业协会 “提供服务、反映诉求、规范行为”作用，特制定本公约。</p>
          <h4>一、基本内容</h4>
          <p>行业自律就是自己约束自己，行业自律包括二个方面，一方面是行业内对国家法律、法规政策的遵守和贯彻；另一方面是行业内的行规行约制约自己的行为。而每一方面都包含对行业内成员的监督和保护的机能。</p>
          <h4>二、行业自律的内涵</h4>
          <p>行业自律的内涵主要包括以下五方面：<br>
            &emsp;一是严格执行相关的法律、法规、行业管理办法、合同法、其他相关法律、法规。<br>
            二是制定和认真执行行规行约。<br>
            “行规和行约”是行业内部自我管理、自我约束的一种措施。行规和行约的制定和执行对会员无疑起到一种自我监督的作用，推动本行业规范健康的发展。<br>
            三是向会企提供优质、规范服务。<br>
            四是行业自律也是维护本行业和企业的利益，避免恶性竞争，维护本行业持续健康的发展。<br>
            五是行业协会是行业自律的当然监督机构之一。<br>
            行业自律是建立在行业协会的基础之上的，如果一个行业没有一个行之有效的行业协会的话，行业自律也就无从谈起。行业自律是市场经济体制的必然产物。每个行业只有认真地做好了行业自律的工作，才能得以在竞争激烈的市场中生存下去，我们也才能有一个健康有序的市场。</p>
          <h4>三、总则</h4>
          <p>第一条  遵守宪法、法律、法规和国家政策，自觉践行社会主义核心价值观，带头做到廉洁诚信，做廉洁诚信的坚定执行者和忠实捍卫者。<br>
            第二条  坚持以章程为核心，建立健全并严格遵守换届选举、议事决策、人事管理、财务管理、机构管理等各项规章制度。把廉洁诚信建设内容纳入行业协会章程。<br>
            第三条  根据行业发展要求，研究制定行业自律规约；协助政府制定行业标准，完善行业规范和活动规则。<br>
            第四条  建立健全会员企业信用档案，积极开展会员企业信用评价，加强会员企业信用信息共享和应用，帮助会员企业提高信用管理能力。<br>
            第五条  严格遵守办会宗旨，彰显公益性，增强服务意识，提高服务能力。不乱收费、乱评比，不强行服务，不超出章程规定的业务范围开展活动。<br>
            第六条  严格遵守财务管理制度，保证会计资料合法、真实、准确、完整。定期向会员大会、理事会报告财务收支情况，增加透明度和公信力，自觉接受监督。<br>
            第七条  开展各种形式的廉洁诚信文化建设，广泛开展廉洁诚信教育，强化廉洁从业、诚信从业和公平竞争意识，自觉抵制和反对不正当行为。<br>
            第八条  及时了解、收集在行业自律方面存在的问题，向政府反映会员的意见和建议，在防止滥用政府权力发挥积极作用。<br>
            第十条  陕西西咸新区服务贸易协会(以下简称“协会”)是本公约的发布组织和实施机构，受西陕西省西咸新区改革创新发展局、陕西省西咸新区行政审批与政务服务局的监督指导。<br>
            第十一条  协会负责指导、检查会员对本公约的履行情况，有权将履行情况向社会公布。<br>
            第十二条  任何单位和个人均有权举报违反公约的行为。协会应对举报的情况组织调查、核实，相关单位和人员应予以配合，如实提供所需资料。<br>
            第十三条  任何单位和个人均有权对协会组织实施本公约的公正性进行监督。</p>
          <h4>四、细则</h4>
          <p>第十四条  从事服务贸易行业机构及其从业人员应当自觉遵守国家的法律法规、技术标准，依法经营，维护行业声誉，恪守职业道德。<br>
            第十五条  从事服务贸易行业机构及其从业人员应当公开、公正、诚信、独立自主地开展业务，认真履行合同，为客户提供优质的服务。<br>
            第十六条  从事服务贸易行业机构在执业过程中，应当以质量、技术和信誉赢得市场，参考相关法律法规及行业价格指南合理报价，反对劣质低价、无序竞争等扰乱市场秩序行为。<br>
            第十七条  从事服务贸易行业机构应依法与从业人员签订劳动合同，缴纳社会保险，组织从业人员按规定参加继续教育。<br>
            第十八条  从事服务贸易行业机构在执业过程中，应当遵守下列行为规范：<br>
            （一）与委托方签订服务合同，明确专业服务对象、范围以及双方权利、义务和责任，存在利害关系时，应当回避；<br>
            （二）参加竞争性谈判时应自觉维护招标、投标秩序，遵循正规流程，杜绝“暗箱”操作；<br>
            （三）遵守市场竞争规则，依法经营，廉洁自律，杜绝商业贿赂和其他形式的经济犯罪行为，维护市场秩序和行业正气；<br>
            （四）同行互尊、互助、互促、互学，加强交流、共同提高；<br>
            （五）现场勘查记录、检测检验数据、影像资料、过程控制记录、培训考核记录等相关证明材料，应如实记录，及时归档，妥善保管；<br>
            （六）安全评价、检测检测、标准化评审等技术报告应当认真编制，严格校审，确保结果的科学、客观、正确；<br>
            （七）强化从业人员业务培训，积极研究并采用新技术、新方法，不断促进行业技术创新；<br>
            （八）自觉接受协会和社会的监督；<br>
            （九）不得私下委托其他机构的从业人员编写报告及开展相关工作。<br>
            第十九条  从事服务贸易行业机构在执业过程中应当禁止下列行为：<br>
            （一）承接检测、评价、培训考核、标准化评审等专业服务业务时，做出保证通过或取得证书等违背原则的承诺；<br>
            （二）从委托单位接受、向委托单位索取与技术服务合同无关的好处或采取商业贿赂方法获得项目；<br>
            （三）对管理部门要求填报的情况敷衍了事，甚至弄虚作假；<br>
            （四）以任何欺骗手段获得客户信任，招揽业务；<br>
            （五）借用或出借相关资质；<br>
            （六）档案资料不按规定管理；<br>
            （七）恶意造谣、诋毁其他机构及从业人员；<br>
            （八）其他违反国家法律法规的行为。</p>
          <h4>五、实施与监督</h4>
          <p>第二十条  协会定期或不定期对会员单位公约执行情况组织进行检查，以保证本公约的有效实施，各会员单位应予以配合，如实提供所需资料。<br>
            第二十一条  对于在履行本公约过程中表现优秀的机构或个人，协会将进行表彰，并在网站公告。<br>
            第二十二条  从事服务贸易行业机构及其从业人员在执业过程中违反本公约第十八条、第十九条的，协会可根据情节严重程度给予通报批评、公告谴责以及上报主管部门等方式处理。<br>
            第二十三条  协会发现机构或个人有可能违反本公约时，应及时制止。对于违反本公约的机构或个人，协会秘书处可依据本公约提出处理意见，并经常务理事会半数以上会员表决通过后执行。</p>
          <h4>六、附则</h4>
          <p>第二十四条  本公约由协会理事会组织会员单位讨论通过后实施。<br>
            第二十五条  本公约由陕西西咸新区服务贸易协会理事会负责解释。<br>
            第二十六条  本公约自发布之日起生效。</p>
        </div>
      </div>
      <div slot="rightDiv" class="right">
        <div class="photo"></div>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";

export default {
  name: "disciplineContent",
  components: {
    leftAndRight
  }
}
</script>

<style scoped>
.right img {
  width: 100%;
}
.left p{
  font-size: 14px;
  color: #666666;
  line-height: 25px;
}

.photo {
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 10px;
  background-image: url("../../assets/image/xieshougongyin.png");
  background-size: 150% 100%;
  background-position: 50% 50%;
}

.photo img {

}
</style>